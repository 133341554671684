import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";

const MyHoverIcons = ({
  muiIcon,
  muiIconHover,
  style,
  onClick,
  tooltip,
  ...props
  //   disabled
}) => {
  const [renderIcon, setRenderIcon] = useState(muiIcon);

  useEffect(() => {
    setRenderIcon(muiIcon);
  }, [muiIcon]);

  return (
    <div
      {...props}
      onClick={onClick}
      style={{ ...style, cursor: "pointer" }}
      onMouseEnter={() => setRenderIcon(muiIconHover)}
      onMouseLeave={() => setRenderIcon(muiIcon)}
    >
      {tooltip && renderIcon ? (
        <Tooltip title={tooltip}>{renderIcon}</Tooltip>
      ) : renderIcon ? (
        renderIcon
      ) : null}
    </div>
  );
};

export default MyHoverIcons;
