import * as React from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@material-ui/lab/index";

export default function MyAutocomplete({
  options,
  label,
  value,
  multiple,
  InputLabelProps,
  ...props
}) {
  return (
    <Autocomplete
      //   disablePortal
      {...props}
      multiple={multiple}
      id="combo-box-demo"
      value={multiple ? (value ? value : []) : value ? value : null}
      options={options}
      style={{ width: "100%", color: "red" }}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          id="outlined-basic"
          label={label}
          variant={props.variant || "outlined"}
          style={{
            width: "100%",
          }}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            disableUnderline: true,
          }}
          InputLabelProps={InputLabelProps}
        />
      )}
      {...props}
    />
  );
}
