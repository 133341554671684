import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  formatIndianNumber,
  imageExpired,
  isVendor,
  oopsNotFound,
  roundOff,
} from "@ui/Utils/helper";
import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

export const FullscreenPreview = ({ open, onClose, imageSrc }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullScreen
    PaperProps={{
      sx: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    }}
  >
    <IconButton
      onClick={onClose}
      style={{
        position: "absolute",
        top: 16,
        right: 16,
        color: "#fff",
        backgroundColor: "#c7c7c7",
        zIndex: 4,
      }}
    >
      <CloseIcon />
    </IconButton>

    <img
      src={imageSrc || oopsNotFound}
      alt="Preview"
      style={{ width: "90%", height: "100%", objectFit: "contain" }}
      onContextMenu={(e) => e.preventDefault()}
      onError={(event) => {
        event.target.src = imageExpired;
      }}
    />
  </Dialog>
);

const ProductDetailPopup = ({ open, onClose, product }) => {
  const [additionalMedia, setAdditionalMedia] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentPreviewImage, setCurrentPreviewImage] = useState(null);

  const handleImageClick = (src) => {
    setCurrentPreviewImage(src);
    setPreviewOpen(true);
  };

  useEffect(() => {
    (async () => {
      try {
        if (product.styleNo) {
          const mediaData = await getSingleRecord(
            enums.models["data-engine"].additionalMedia,
            {
              styleNo: product.styleNo,
              filterColumns: { media: 1 },
            }
          );
          setAdditionalMedia(mediaData?.media || []);
        }
      } catch (err) {}
    })();
  }, [product]);

  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.signedUrl;
    link.download = file.fileName;
    link.click();
  };

  const isImageFile = (fileType) =>
    ["image/jpeg", "image/png", "image/gif", "image/webp"].includes(fileType);

  const isVideoFile = (fileType) =>
    [
      "video/mp4",
      "video/webm",
      "video/ogg",
      "video/quicktime", // .mov
      "video/x-msvideo", // .avi
      "video/mpeg", // .mpeg
      "video/x-matroska", // .mkv
      "video/x-flv", // .flv
      "video/3gpp", // .3gp
      "video/x-ms-wmv", // .wmv
    ].includes(fileType);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 3,
          padding: 2,
          backgroundColor: "#fff",
          userSelect: "none",
        },
      }}
    >
      <DialogTitle
        style={{
          padding: "16px 24px",
          display: "flex",
          justifyContent: "space-between",
          userSelect: "none",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          style={{
            color: "#075985",
          }}
        >
          {product.styleNo}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ padding: "16px 24px" }}>
        <Grid container spacing={3}>
          {/* Main Product Details */}
          <Grid item xs={12} md={6}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                width: "100%", // Ensures the Box takes the full width
              }}
            >
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                autoPlay={false}
                interval={3000}
                stopOnHover
                useKeyboardArrows
                emulateTouch
                swipeable
                dynamicHeight={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton
                      onClick={onClickHandler}
                      onMouseEnter={(event) => {
                        event.target.style.backgroundColor =
                          "rgba(0, 0, 0, 0.7)";
                      }}
                      onMouseLeave={(event) => {
                        event.target.style.backgroundColor =
                          "rgba(0, 0, 0, 0.5)";
                      }}
                      style={{
                        position: "absolute",
                        left: 30,
                        bottom: 0,
                        zIndex: 2,
                        height: "42px",
                        width: "42px",
                        color: "#fff",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                      aria-label={label}
                    >
                      ◀
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton
                      onClick={onClickHandler}
                      onMouseEnter={(event) => {
                        event.target.style.backgroundColor =
                          "rgba(0, 0, 0, 0.7)";
                      }}
                      onMouseLeave={(event) => {
                        event.target.style.backgroundColor =
                          "rgba(0, 0, 0, 0.5)";
                      }}
                      style={{
                        position: "absolute",
                        right: 30,
                        bottom: 0,
                        height: "42px",
                        width: "42px",
                        zIndex: 2,
                        color: "#fff",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                      aria-label={label}
                    >
                      ▶
                    </IconButton>
                  )
                }
              >
                <div
                  style={{ position: "relative", height: "400px" }}
                  onClick={() =>
                    handleImageClick(
                      product.defaultImage?.signedUrl || oopsNotFound
                    )
                  }
                >
                  <img
                    src={product.defaultImage?.signedUrl || oopsNotFound}
                    onError={(event) => {
                      event.target.src = imageExpired;
                    }}
                    alt={product.styleNo}
                    style={{
                      width: "100%",
                      height: "100%", // Make sure image height is controlled
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  <IconButton
                    onMouseEnter={(event) => {
                      event.target.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
                    }}
                    onMouseLeave={(event) => {
                      event.target.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
                    }}
                    style={{
                      position: "absolute",
                      top: 16,
                      right: 16,
                      color: "#fff",
                      zIndex: 3,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </div>
                {additionalMedia.map((media, index) =>
                  isImageFile(media.fileType) ? (
                    <div
                      key={index}
                      style={{ position: "relative", height: "400px" }}
                      onClick={() => handleImageClick(media.signedUrl)}
                    >
                      <img
                        src={media.signedUrl}
                        alt={media.fileName}
                        style={{
                          width: "100%",
                          height: "100%", // Make sure image height is controlled
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                        onError={(event) => {
                          event.target.src = imageExpired;
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                      <IconButton
                        // onMouseEnter={(event) => {
                        //   event.target.style.backgroundColor =
                        //     "rgba(0, 0, 0, 0.7)";
                        // }}
                        // onMouseLeave={(event) => {
                        //   event.target.style.backgroundColor =
                        //     "rgba(0, 0, 0, 0.5)";
                        // }}
                        style={{
                          position: "absolute",
                          top: 16,
                          right: 16,
                          color: "#fff",
                          zIndex: 3,
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    </div>
                  ) : isVideoFile(media.fileType) ? (
                    <div key={index} style={{ height: "400px" }}>
                      <video
                        controls
                        controlsList="nodownload"
                        style={{
                          width: "100%",
                          height: "100%", // Ensure video fits within fixed height
                          borderRadius: "8px",
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        <source src={media.signedUrl} type={media.fileType} />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        onClick={() => handleDownload(media)}
                      >
                        Download {media.fileName}
                      </Button>
                    </div>
                  )
                )}
              </Carousel>
              {isVendor ? null : (
                <Typography variant="h6" style={{ marginTop: 2 }}>
                  ₹{" "}
                  {product.jewelPrice
                    ? formatIndianNumber(roundOff(product.jewelPrice))
                    : "..."}
                </Typography>
              )}

              {/* Fullscreen image preview dialog */}
              {currentPreviewImage && (
                <FullscreenPreview
                  open={previewOpen}
                  onClose={() => setPreviewOpen(false)}
                  imageSrc={currentPreviewImage}
                />
              )}
            </Box>
          </Grid>

          {/* Additional Details */}
          <Grid item xs={12} md={6}>
            <Card style={{ backgroundColor: "#f5f5f5", borderRadius: 2 }}>
              <CardContent>
                {product.styleNo ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Design No: <b>{product.styleNo}</b>
                  </Typography>
                ) : null}
                {product.sku ? (
                  <Typography variant="subtitle1" gutterBottom>
                    SKU: <b>{product.sku}</b>
                  </Typography>
                ) : null}
                <br />
                <br />
                {product.category?.name ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Category: <b>{product.category?.name}</b>
                  </Typography>
                ) : null}
                {product.subCategory?.name ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Sub Category: <b>{product.subCategory?.name}</b>
                  </Typography>
                ) : null}
                {product.collectionLine?.name ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Collection Line: <b>{product.collectionLine?.name}</b>
                  </Typography>
                ) : null}

                <br />
                <br />

                <Typography variant="subtitle1" gutterBottom>
                  Net Weight:{" "}
                  <b>{roundOff(product.netWeight, { weight: true })} g</b>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Gross Weight:{" "}
                  <b>{roundOff(product.grossWeight, { weight: true })} g</b>
                </Typography>

                <br />
                <br />

                {product.purity?.value ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Purity:{" "}
                    <b>
                      {product.purity?.value
                        ? product.purity?.value + " KT"
                        : ""}
                    </b>
                  </Typography>
                ) : null}
                {product.color ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Color: <b>{product.color}</b>
                  </Typography>
                ) : null}
                {product.screw ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Screw: <b>{product.screw}</b>
                  </Typography>
                ) : (
                  ""
                )}
                {product.setting ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Setting: <b>{product.setting}</b>
                  </Typography>
                ) : null}

                {product.specificEngraving ? (
                  <Typography variant="subtitle1" gutterBottom>
                    Engraving: <b>{product.specificEngraving}</b>
                  </Typography>
                ) : null}
              </CardContent>
            </Card>
          </Grid>

          {/* Diamond Details */}
          {product.diamondDetails?.length ? (
            <Grid item xs={12}>
              <Card style={{ backgroundColor: "#e0f7fa", borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6" style={{ marginBottom: 2 }}>
                    Diamond Details
                  </Typography>
                  {product.diamondDetails &&
                  product.diamondDetails.length > 0 ? (
                    <Grid container spacing={2}>
                      {product.diamondDetails.map((diamond, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          💎 {index + 1}
                          <Box
                            sx={{
                              padding: 1,
                              backgroundColor: "#fff",
                              borderRadius: 2,
                            }}
                          >
                            <Typography variant="body2">
                              <strong>Quantity:</strong>{" "}
                              {diamond.diamondQuantity}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Total Carat:</strong>{" "}
                              {roundOff(diamond.totalCarat, {
                                weight: true,
                                isDiamond: true,
                              })}
                            </Typography>

                            <div
                              style={{
                                marginTop:
                                  diamond.shape || diamond.cut || diamond.color
                                    ? "24px"
                                    : "",
                              }}
                            >
                              {diamond.shape ? (
                                <Typography variant="body2">
                                  <strong>Shape:</strong> {diamond.shape}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {diamond.cut ? (
                                <Typography variant="body2">
                                  <strong>Cut:</strong> {diamond.cut}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {diamond.color ? (
                                <Typography variant="body2">
                                  <strong>Color:</strong> {diamond.color}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </div>

                            <div
                              style={{
                                marginTop:
                                  diamond.clarity ||
                                  diamond.polish ||
                                  diamond.symmetry
                                    ? "24px"
                                    : "",
                              }}
                            >
                              {diamond.clarity ? (
                                <Typography variant="body2">
                                  <strong>Clarity:</strong> {diamond.clarity}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {diamond.polish ? (
                                <Typography variant="body2">
                                  <strong>Polish:</strong> {diamond.polish}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {diamond.symmetry ? (
                                <Typography variant="body2">
                                  <strong>Symmetry:</strong> {diamond.symmetry}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </div>

                            <div
                              style={{
                                marginTop:
                                  diamond.gradingLab ||
                                  diamond.fluorescenceIntensity ||
                                  diamond.isSolitaire ||
                                  diamond.sieveSize
                                    ? "24px"
                                    : "",
                              }}
                            >
                              {diamond.gradingLab ? (
                                <Typography variant="body2">
                                  <strong>Grading Lab:</strong>{" "}
                                  {diamond.gradingLab}
                                </Typography>
                              ) : null}
                              {diamond.fluorescenceIntensity ? (
                                <Typography variant="body2">
                                  <strong>Fluorescence:</strong>{" "}
                                  {diamond.fluorescenceIntensity}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {diamond.isSolitaire ? (
                                <Typography variant="body2">
                                  <strong>Solitaire Size:</strong>{" "}
                                  {diamond.solitaireSize?.length || 0} x{" "}
                                  {diamond.solitaireSize?.width || 0} mm
                                </Typography>
                              ) : (
                                <Typography variant="body2">
                                  <strong>Sieve Size:</strong>{" "}
                                  {diamond.sieveSize?.length || 0} x{" "}
                                  {diamond.sieveSize?.width || 0} mm
                                </Typography>
                              )}
                            </div>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="body2">
                      No diamond details available
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          onClick={onClose}
          style={{
            backgroundColor: "#075985",
            color: "#EFF6FF",
            height: "48px",
            width: "150px",
            fontWeight: "bold",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDetailPopup;
